import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import GutenbergBlocks from "../components/gutenberg-blocks";
import PropTypes from "prop-types";
import Hero from "../components/hero";
import Navbar from "../components/navbar";
import SEO from "../components/seo";
import { createBreadcrumbList } from "../utils/breadcrumb";

const Page = ({
  data: {
    wpPage: { blocks, featuredImage, title, excerpt, seo, ancestors, uri },
  },
}) => (
  <Layout>
    <SEO
      title={seo.title}
      description={seo.metaDesc || excerpt}
      breadcrumbs={createBreadcrumbList(ancestors, uri, title)}
    />
    {featuredImage && <Hero image={featuredImage.node} />}
    <Navbar />
    <GutenbergBlocks blocks={blocks} />
  </Layout>
);

Page.propTypes = {
  data: PropTypes.object,
};

export default Page;

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      excerpt
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      blocks {
        ...GutenbergBlocks
      }
      uri
      ancestors {
        nodes {
          ... on WpPage {
            uri
            title
          }
        }
      }
      seo {
        metaDesc
        title
      }
    }
  }
`;
