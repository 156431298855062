import React from "react";
import PropTypes from "prop-types";
import { theme } from "../style/theme.js";
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";
import Hidden from "./hidden.js";

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 10px 10px 10px;
  border: 1px solid ${theme.colors.gray};
  @media (max-width: 1112px) {
    justify-content: center;
  }
  @media (max-width: 514px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 8px;
    -webkit-overflow-scrolling: touch;
    margin: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
const LeftContainer = styled.div`
  border-right: 1px solid ${theme.colors.gray};
  padding: 0 5px;
  font-size: 1.2rem;
  min-height: 40px;
  display: flex;
  align-items: center;
  @media (max-width: 1112px) {
    font-size: 0.9rem;
  }
  @media (max-width: 514px) {
    display: none;
  }
`;
const CenterContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-size: 1rem;
  @media (max-width: 1112px) {
    font-size: 0.9rem;
  }
  @media (max-width: 514px) {
    flex: 0 0 auto;
    width: 500px;
    align-items: flex-start;
    padding: 15px 0 10px 0;
    position: relative;
  }
`;
const RightContainer = styled.div`
  border-left: 1px solid ${theme.colors.gray};
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 1023px) {
    display: none;
  }
`;
const LinkUrl = styled((props) => <Link {...props} />)`
  text-decoration: none;
  transition: 0.3s all ease;
  margin: 0 15px;
  border-bottom: 3px solid transparent;
  &:hover {
    border-bottom: 3px solid ${theme.colors.primary};
  }
  @media (max-width: 1112px) {
    margin: 0 10px;
    text-align: center;
  }
  @media (max-width: 514px) {
    padding: 10px 15px;
    margin: 7px;
    border: 1px solid ${theme.colors.black};
    border-radius: 10px;
    &:hover {
      border: 1px solid ${theme.colors.white};
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  }
`;
const ContactUrl = styled.a`
  text-decoration: none;
  transition: 0.3s all ease;
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;
  font-size: 1.2rem;
  &:hover {
    border-bottom: 1px solid ${theme.colors.primary};
  }
`;
const ContactLabel = styled.p`
  font-size: 0.8rem;
  margin: 0;
`;
const MobileTitle = styled.p`
  font-size: 0.8rem;
  margin: 0;
  position: absolute;
  top: 0;
  left: 10px;
`;

export default function NavBar({ staticQueryData }) {
  console.log(staticQueryData);
  const { navBar } =
    staticQueryData ||
    useStaticQuery(graphql`
      fragment NavBar on WpMenuItem {
        order
        id
        url
        cssClasses
        label
        title
      }

      query NavBarQuery {
        navBar: allWpMenuItem(
          filter: { locations: { eq: PRODUCT_CAT_NAV }, parentId: { eq: null } }
          sort: { fields: order, order: ASC }
        ) {
          edges {
            node {
              ...NavBar
              childItems {
                nodes {
                  ...NavBar
                }
              }
            }
          }
        }
      }
    `);

  const navItems = navBar.edges.map((edge) => {
    return {
      id: edge.node.id,
      url: edge.node.url,
      title: edge.node.label,
      label: edge.node.title,
      isContact: edge.node.url.startsWith("tel:"),
      subLinks: edge.node.childItems?.nodes?.map((node) => ({
        id: node.id,
        url: node.url,
        title: node.label,
      })),
    };
  });

  const menuItems = navItems.filter((item) => !item.isContact);
  const contacts = navItems.filter((item) => item.isContact);

  return (
    <span style={{ width: "100%" }}>
      {menuItems.map((node) => (
        <Wrapper key={node.id}>
          <LeftContainer>
            <LinkUrl
              key={node.id}
              to={node.url}
              activeClassName="active-product-link"
            >
              {node.title}
            </LinkUrl>
          </LeftContainer>
          <Hidden for="mobile">
            <CenterContainer>
              {node.subLinks &&
                node.subLinks.map((item) => (
                  <LinkUrl
                    key={item.id}
                    to={item.url}
                    activeClassName="active-product-link"
                  >
                    {item.title}
                  </LinkUrl>
                ))}
            </CenterContainer>
          </Hidden>
          <Hidden for="desktop">
            <CenterContainer>
              <MobileTitle>Produkter</MobileTitle>
              {node.subLinks &&
                node.subLinks.map((item) => (
                  <LinkUrl
                    key={item.id}
                    to={item.url}
                    activeClassName="active-product-link"
                  >
                    {item.title}
                  </LinkUrl>
                ))}
            </CenterContainer>
          </Hidden>
          <RightContainer>
            {contacts.map((contact) => (
              <React.Fragment key={contact.id}>
                <ContactLabel>{contact.label}:</ContactLabel>
                <ContactUrl href={contact.url}>{contact.title}</ContactUrl>
              </React.Fragment>
            ))}
          </RightContainer>
        </Wrapper>
      ))}
    </span>
  );
}

NavBar.propTypes = {
  staticQueryData: PropTypes.object,
};
